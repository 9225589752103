import { useContext, useEffect, useState } from "react";
import "./buydesoinu.css";
import { LatestPosts } from "./latest-posts";
import {buyCreatorCoin, identity} from 'deso-protocol';
import { DeSoIdentityContext } from "react-deso-protocol";

export const BuyDesoInu = ({desoPrice, desoInuProfile})=> {
    const { currentUser, isLoading } = useContext(DeSoIdentityContext);
    const [amountToBuy, setAmountToBuy] = useState(1);
    //const [desoPrice, setDesoPrice] = useState(3.1);
    const [tokenPrice, setTokenPrice] = useState(0.1);
    const [tokenPriceNanos, setTokenPriceNanos] = useState(0);
    useEffect(()=>{
        ////console.log(desoInuProfile, "Profile ???");
        setTokenPrice(desoInuProfile.CoinPriceDeSoNanos * 10 ** -9);
        setTokenPriceNanos(desoInuProfile.CoinPriceDeSoNanos);
    }, [desoInuProfile]);

    useEffect(()=>{
        if (currentUser){
            setAmountToBuy((currentUser.BalanceNanos*10**-9)*0.9)
        }
    }, [currentUser])
    return (
        <div id="buydesoinu-container" className="grid sm:grid-cols-1 md:grid-cols-2 gap-20">
            <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-20">
                <div className="grid grid-rows-1">
                    <div>
                        <img src="/logo.png" />
                    </div>
                    <div className="tokenPrice">
                        Token Price: ${parseFloat((tokenPrice*desoPrice).toFixed(2))} usd
                    </div>
                <div className="goToProfile">
                    <a href="https://www.diamondapp.com/u/DeSoInu" target="_blank"> Go To Profile</a>
                </div>
                </div>
                <div className="buy-container grid grid-cols-1">
                    <div>
                        <h1>Buy DeSo Inu</h1>
                    </div>
                    <div className="grid grid-cols-1">
                        <div className='text-white'>Spend</div>
                        <input value={amountToBuy} onChange={(e)=>{
                             if(e.target.value > (currentUser?.BalanceNanos*10**-9)*0.9) {
                                setAmountToBuy((currentUser?.BalanceNanos*10**-9)*0.9);
                            }
                            else setAmountToBuy(e.target.value)
                            }} min="0" max={currentUser? (currentUser?.BalanceNanos*10**-9)*0.9 : 1000} type="number" step="1" />
                        <div className='text-white'>DeSo</div>
                    </div>
                    <div>
                        You wil get ~{parseFloat(((amountToBuy*(100-(desoInuProfile.CoinEntry.CreatorBasisPoints*10**-2))/100)/tokenPrice).toFixed(2))} Tokens
                    </div>
                    
                    {
                        currentUser ? 
                        <div className="tokenBalance">
                            Your DeSo Balance: {parseFloat((currentUser.BalanceNanos*10**-9).toFixed(4))} DeSo (~${parseFloat((desoPrice*currentUser.BalanceNanos*10**-9).toFixed(4))} usd)
                        </div>: ''
                    }
                    <div>
                        <button className="buyBtn" onClick={()=>{
                            if(currentUser){
                                buyCreatorCoin({
                                    CreatorPublicKeyBase58Check: "BC1YLhAMNXNaWDe6qpKcoah7dvVMgJp3SizCom2Yus6rqhyzQ3caYEE",
                                    UpdaterPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
                                    DeSoToSellNanos: (parseInt(amountToBuy*10**9))
                                    
                                })
                            }
                            else {
                                identity.login();
                            }
                        }}>{currentUser? "Buy": "Login To Buy"}</button>
                    </div>
                </div>
            </div>

            <div>
                <div><LatestPosts desoPrice={desoPrice} desoInuProfile={desoInuProfile} /></div>
            </div>
        </div>
    )
};