import {OpenAI} from "openai";
import { useEffect } from "react";


export const BotComponent = ()=>{
    const openai = new OpenAI({
        apiKey: '',
        baseURL: 'http://localhost:4891/v1',
        dangerouslyAllowBrowser: true
      });
      useEffect(async ()=>{
        const chatCompletion = await openai.chat.completions.create({
            messages: [{ role: 'user', content: 'Say this is a test' }],
            model: 'mistral-7b-openorca.Q4_0',
          });
          console.log(chatCompletion);
      },[])
    return (
        <>
            Woof Woof ! 🦊🚀
        </>
    )
};