import React from "react";
import { DeSoIdentityProvider } from "react-deso-protocol";
import ReactDOM from "react-dom/client";
import {configure} from "deso-protocol";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import { Home } from "./routes/home";
import { Root } from "./routes/root";
import { BotComponent } from "./routes/bot";
import { SignAndSubmitTx } from "./routes/sign-and-submit-tx";
import { SwitchAccount } from "./routes/switch-account";
import { User } from "./routes/user";
configure({
  spendingLimitOptions: {
    IsUnlimited: true,
    GlobalDESOLimit: 0.01 * 1e9,
    TransactionCountLimitMap: {
      LIKE: 1000000,
      REACTION: 'UNLIMITED',
      CREATE_POST_ASSOCIATION: 1000000,
      CREATE_USER_ASSOCIATION: 1000000,
      DELETE_POST_ASSOCIATION: 1000000,
      DELETE_USER_ASSOCIATION: 1000000     
    },
  }
})

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      { path: "/", element: <Home /> },
      { path: "/bot", element: <BotComponent /> },
      {
        path: "/u/:username",
        element: <User />,
      },
      {
        path: "/sign-and-submit-tx",
        element: <SignAndSubmitTx />,
      },
      {
        path: "/switch-account",
        element: <SwitchAccount />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <>
    <DeSoIdentityProvider>
      <RouterProvider router={router} />
    </DeSoIdentityProvider>
  </>
);
