import { getHolders } from 'deso-protocol';
import { useEffect, useState } from 'react';
import './hodlers.css';
export const HodlersComponent = ({desoPrice, desoInuProfile})=>{
    const [hodlers, setHodlers] = useState();
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState([]);
    const [initialized, init] = useState(false);
    const [isLoadingHodlers, setIsLoadingHodlers] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [hodlersPerPage, setHodlersPerPage] = useState(10);
      // Función para agregar un nuevo objeto a un array específico
  const addPage = (index, _hodlers) => {

    // Copiamos el array original
    const pagesCopy = [...pages];
    //console.log(index, _hodlers, pagesCopy);
    // Agregamos el nuevo objeto al array específico
    pagesCopy[index] = _hodlers;

    // Actualizamos el estado
    setPages(pagesCopy);
    //console.log(pages);
  };

    useEffect(()=>{
        if(!initialized){
            getHolders({
                //PublicKeyBase58Check: 'BC1YLifc2wKESLRLTLUrjFaWbSJnhYuP4wEpAKgYM7vVCqsPVuVjf8A',
                Username: 'DeSoInu',
                NumToFetch: hodlersPerPage,
                //LastPublicKeyBase58Check: "BC1YLhnVDKagxrokmhPiawvZUDvPHj3JfPdd4knZ3nCraPdFrgzPef1"  
            }).then(async (_hodlers)=>{
                //console.log(hodlers.Hodlers.length, _hodlers.Hodlers.length);
                if(hodlers?.Hodlers.length != _hodlers?.Hodlers.length)
                { 
                    setHodlers(_hodlers);
                    addPage(page, _hodlers);
                }
                //console.log(pages);
                setIsLoadingHodlers(false);
            })
            init(true);
        }
    }, [initialized]);

    useEffect(()=>{
        setTotalPages(Math.ceil(desoInuProfile?.CoinEntry.NumberOfHolders / hodlersPerPage));
    }, [desoInuProfile])
    return (
        
        <div className="hodlersContainer">
        <h1 className='header1'>Hodlers <span className='a text-2xl'>({desoInuProfile?.CoinEntry.NumberOfHolders})</span></h1>
            <div className="grid md:grid-cols-8 sm:grid-cols-1 gap-0">
                <div className='col-span-1 flex' onClick={()=>{
                    if(page > 0) {
                        setHodlers(pages[page-1]);
                        setPage(page-1);
                        //console.log(page);
                    }
                    

                    //console.log(pages[page]);
                }}><div className='m-auto'>{page > 0? <img src='/prev.svg' className='prevNext'/>:''}</div></div>
                <div className='grid md:grid-cols-2 sm:grid-cols-1 gap-10 sm:col-span-1 md:col-span-6'>
                    {
                        hodlers?.Hodlers.map((value, key)=>{
                            if(value.ProfileEntryResponse && value.ProfileEntryResponse.Username?.toLowerCase().search('scam') < 0)  
                                return (
                                    <div className="profileEntry rounded-full p-4 bg-gray-100" key={'hodler-'+key}>
                                            
                                        <a href={'https://diamondapp.com/u/'+value.ProfileEntryResponse.Username} target="_blank">
                                            {value.ProfileEntryResponse.Username.toLowerCase().search('scam') < 0 ? <div className="profilePic" style={{float:'left',backgroundImage: 'url(https://diamondapp.com/api/v0/get-single-profile-picture/'+value.ProfileEntryResponse.PublicKeyBase58Check+'?fallback=https://diamondapp.com/assets/img/default-profile-pic.png)'}}>&nbsp;</div> : '' }
                                            <div className="username" style={{float: 'left'}}>{value?.ProfileEntryResponse.Username}</div>
                                            <div className="coinPrice" style={{float: 'left'}}>( ~${parseFloat((((value?.ProfileEntryResponse.CoinPriceDeSoNanos)*10**-9)*desoPrice).toFixed(2))} usd )</div>
                                        </a>
                                    </div>
                                )
                            else {
                                return(

                                    <div className="profileEntry rounded-full p-4 bg-gray-100" key={'hodler-'+key}>
                                         {value.ProfileEntryResponse?.Username?.toLowerCase().search('scam') < 0 ? <div className="profilePic" style={{float:'left',backgroundImage: 'url(https://diamondapp.com/api/v0/get-single-profile-picture/'+value.ProfileEntryResponse.PublicKeyBase58Check+'?fallback=https://diamondapp.com/assets/img/default-profile-pic.png)'}}>&nbsp;</div> : <div className="profilePic" style={{float:'left',backgroundImage: 'url(https://diamondapp.com/assets/img/default-profile-pic.png)'}}>&nbsp;</div> }
                                        <div className="username truncate" style={{float: 'left', maxWidth:'200px'}}>{value?.HODLerPublicKeyBase58Check}</div>
                                    </div>
                                )
                            }
                        })
                    }
                </div>

                <div className='flex col-span-1' onClick={()=>{
                    if(!pages[page+1] && (page+1) < totalPages){
                        setIsLoadingHodlers(true);
                        getHolders({
                            //PublicKeyBase58Check: 'BC1YLifc2wKESLRLTLUrjFaWbSJnhYuP4wEpAKgYM7vVCqsPVuVjf8A',
                            Username: 'DeSoInu',
                            NumToFetch: hodlersPerPage,
                            LastPublicKeyBase58Check: hodlers.Hodlers[hodlers.Hodlers.length-1].HODLerPublicKeyBase58Check 
                        }).then(async (_hodlers)=>{
                            setHodlers(_hodlers);
                            addPage(page+1, _hodlers);
                            //console.log(pages);
                            setIsLoadingHodlers(false);
                        })
                        setPage(page+1);
                    }
                    else if((page+1) < totalPages) {
                        setHodlers(pages[page+1]);
                        setPage(page+1);
                    }
                }}><div className='m-auto'>{!isLoadingHodlers && (page+1) < totalPages?<img src='/next.svg' className='prevNext'/>:''}</div></div>
            </div>
        </div>

        
    )
};