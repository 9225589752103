import { identity } from "deso-protocol";
import { useContext } from "react";
import { DeSoIdentityContext } from "react-deso-protocol";
import { Link } from "react-router-dom";
import { getDisplayName } from "../helpers";

export const Nav = () => {
  const { currentUser, isLoading } = useContext(DeSoIdentityContext);

  return (
    <nav className="main-nav fixed w-full">
      <div className="main-nav__user-actions">
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <>
            {!!currentUser && (
              <>
              <div style={{float: 'left'}}>
                <div  className='mx-2 rounded-full' style={{backgroundRepeat: 'no-repeat', width:'50px', height:'50px', backgroundSize: 'cover',backgroundImage: 'url(https://diamondapp.com/api/v0/get-single-profile-picture/'+currentUser.PublicKeyBase58Check+'?fallback=https://diamondapp.com/assets/img/default-profile-pic.png)'}}>&nbsp;</div>
              </div>
              <div className='text-white p-2' style={{float:'left'}}>{currentUser.ProfileEntryResponse.Username}</div>
              </>
            )}
            
            {!currentUser && (
              <button onClick={() => identity.login()}>Login</button>
            )}

            {!!currentUser && (
              <>
              <button onClick={() => identity.logout()}>Logout</button>
              <button onClick={() => identity.login()}>Switch Accounts</button>
              </>
            )}
          </>
        )}
      </div>
    </nav>
  );
};
