import { useEffect, useState } from "react";
import {BuyDesoInu} from "../components/BuyDesoInu";
import { HodlersComponent } from "../components/hodlers";
import { getExchangeRates, getSingleProfile,configure } from "deso-protocol";

export const Home = () => {
  const [desoPrice, setDesoPrice] = useState(0);
  const [desoInuProfile, setDesoInuProfile] = useState();
  
  useEffect(()=>{


    const refetchExchangeRates = ()=> {

      getExchangeRates().then((rate)=>{
        setDesoPrice(rate.USDCentsPerDeSoCoinbase/100);
      });
  
      getSingleProfile({Username: 'DeSoInu'}).then((profile)=>{
        setDesoInuProfile(profile.Profile);
      });
    };

    refetchExchangeRates();

    setInterval(refetchExchangeRates, 5000);

  }, [])

  if(desoPrice) return (
    <div className="grid grid-rows-1 gap-10">
      <div className="heading">
        <h1 className="header1">DeSo Inu</h1>
        <br />
        <h2 className="subHeader">Revolutionizing Social Media on DeSo Blockchain - Monetize Your Content Unlike Any Other Platform !</h2>
        <h2 className="subHeader">Powered by <a href="https://www.deso.com" target="_blank">DeSo Blockchain</a></h2>
      </div>
      <div>
        {desoInuProfile && desoPrice ? <BuyDesoInu desoPrice={desoPrice} desoInuProfile={desoInuProfile} /> : ''}
      </div>
      <div className='w-full m-auto'>
        <HodlersComponent desoPrice={desoPrice} desoInuProfile={desoInuProfile} />
      </div>
    </div>
  );

  else {
    return (
      <>
        Loading ...
      </>
    )
  }
};
