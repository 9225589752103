import { getPostsStateless,  getPostAssociations, createPostAssociation, getIsHolding, getIsFollowing, updateFollowingStatus } from "deso-protocol";
import { useContext, useEffect, useRef, useState } from "react";
import { DeSoIdentityContext } from "react-deso-protocol";
import "./latest-posts.css";
import axios from "axios";

export const LatestPosts = ({desoPrice, desoInuProfile}) => {
  const { currentUser, isLoading } = useContext(DeSoIdentityContext);
  const [initialized, initialize] = useState(false);
  const [feed, setHotFeed] = useState();
  const [intervalId, setIntervalId] = useState(0);
  const [autoRefetch, setAutoRefetch] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isHodler, setIsHodler] = useState({});
  const [likeBot, setLikeBot] = useState(false);
  const [followBot, setFollowBot] = useState(false);
  const [postsToFetch, setPostsToFetch] = useState(10);
  const [likesMultiplier, setLikesMultiplier] = useState(1);
  const [alreadyLiked, setAlreadyLiked] = useState([]);
  const [alreadyFollowing, setAlreadyFollowing] = useState([]);

  const getPosts = ()=>{
    //console.log(postsToFetch);
    getPostsStateless({
      //UpdaterPublicKeyBase58Check: "BC1YLhAMNXNaWDe6qpKcoah7dvVMgJp3SizCom2Yus6rqhyzQ3caYEE",
      NumToFetch: parseInt(postsToFetch),
      
    }).then((resp) => {
        setHotFeed(resp);
        //console.log(resp);
    });
  }

  useEffect(()=>{
    //console.log(currentUser?.PublicKeyBase58Check == 'BC1YLhAMNXNaWDe6qpKcoah7dvVMgJp3SizCom2Yus6rqhyzQ3caYEE');
    setIsAdmin(currentUser?.PublicKeyBase58Check == desoInuProfile.PublicKeyBase58Check);
    if(currentUser && desoInuProfile){
      getIsHolding( {
        PublicKeyBase58Check: currentUser.PublicKeyBase58Check,
        IsHodlingPublicKeyBase58Check: desoInuProfile.PublicKeyBase58Check
      }).then((resp)=>{
        //console.log(resp.IsHodling, resp.BalanceEntry);
        setIsHodler(resp);
      })
    }
    if(!initialized /*&& currentUser?.PublicKeyBase58Check*/) {
      initialize(true);
      ////console.log("initialized!")


      getPosts();
      
      const newIntervalId = setInterval(() => {
        getPosts();
      }, 5000);
  
      setIntervalId(newIntervalId);
    }
    
  }, []);

  useEffect(()=>{
    if(isAdmin) setLikeBot(true);
  }, [isAdmin]);

  useEffect(()=>{
    //console.log(feed);
    if((isAdmin || isHodler) && feed){
      const liked = alreadyLiked;
      feed?.PostsFound.map((v,k)=>{
        if(liked[v.PostHashHex] === undefined) {
          liked[v.PostHashHex] = {liked:false};
        }
      });
      setAlreadyLiked(liked);
      
    }
    if((isAdmin || ((isHodler.BalanceEntry?.BalanceNanos * 1e-9)>= 0.1)) && feed){
      //console.log(isHodler.BalanceEntry?.BalanceNanos * 1e-9);
      const following = alreadyFollowing;
      feed?.PostsFound.map((v,k)=>{
        if(v.ProfileEntryResponse?.PublicKeyBase58Check && following[v.ProfileEntryResponse.PublicKeyBase58Check] === undefined) {
          following[v.ProfileEntryResponse.PublicKeyBase58Check] = {following:false};
        }
      });
      setAlreadyFollowing(following);
    }

  }, [feed]);

  useEffect(()=>{
    if((isAdmin || isHodler) && currentUser && likeBot){
      //console.log("PASA");
      const liked = alreadyLiked;
      for (const [k, v] of Object.entries(liked)) {
        if(!v.liked && k.trim() != '') {
          //console.log('pasa ?');
          liked[k].liked = true;
          getPostAssociations({
            AssociationType: 'REACTION',
            AssociationValue: 'LIKE',
            PostHashHex: k,
            TransactorPublicKeyBase58Check: currentUser.PublicKeyBase58Check
          }).then(async (likes)=>{
            //console.log(likes, likes.Associations.length);
            if((likes.Associations.length < likesMultiplier) && k) {
              for (let x = 0; x<likesMultiplier; x++){
              createPostAssociation({
                PostHashHex: k,
                TransactorPublicKeyBase58Check: currentUser?.PublicKeyBase58Check,
                AssociationType: 'REACTION',
                AssociationValue: 'LIKE'        
              }).then((resp)=>{
                console.log('LIKED!', resp);
              }).catch((e)=>{
                console.log(e);
              })
            }
            }
          });
        }
      };
      setAlreadyLiked(liked);

    }

    
    if((isAdmin || isHodler) && currentUser && followBot){
      const following = alreadyFollowing;
      for (const [k, v] of Object.entries(following)) {
        following[k].following = true;
      getIsFollowing({IsFollowingPublicKeyBase58Check: String(k), PublicKeyBase58Check: currentUser.PublicKeyBase58Check}).then((resp)=>{
        if(!resp.IsFollowing) {
          updateFollowingStatus({            
            FollowerPublicKeyBase58Check: currentUser?.PublicKeyBase58Check,
            FollowedPublicKeyBase58Check: k,
            IsUnfollow: false
          }).then((resp)=>{
            console.log("Now following!", resp);
          })
        }
      }).catch((error)=>{
        console.log(error);
      })
      }
      setAlreadyFollowing(following);
    }

  }, [feed]);

  if (isLoading) {
    return <div>Loading...</div>;
  }
    return (
      
      <div className="latestPosts-container grid grid-cols-1">
        <div><h1 className="text-xl font-bold">What's new on DeSo:</h1></div>
        
        <div className="grid grid-cols-1 gap-4 p-4">
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={autoRefetch}
              onChange={(e) => {
                setAutoRefetch(e.target.checked);
                clearInterval(intervalId);
                if (e.target.checked) {
                  getPosts();
                  const newIntervalId = setInterval(() => {
                    getPosts();
                  }, 5000);
                  setIntervalId(newIntervalId);
                }
              }}
            />
            <span className="ml-2">Auto Refresh</span>
          </div>

          {(isAdmin || isHodler.IsHodling) ? (
            <>
            <div className="flex items-center">
              <input
                type="checkbox"
                checked={likeBot}
                onChange={(e) => {
                  setLikeBot(e.target.checked);
                }}
              />
              <span className="ml-2">Auto Like Assistant <i>(Beta)</i> 👍</span>
            </div>
            </>
          ) : (
            <div className="flex items-center">
              <input type="checkbox" checked disabled />
              <span className="ml-2 grid md:grid-cols-2 sm:grid-cols-1"><div>Auto Like Assistant <i>(Beta)</i> 👍</div> <span style={{fontSize:'12px'}}>(Hold any amount of DeSo Inu to activate)</span></span>
            </div>
          )}
          
          {(isAdmin || ((isHodler.BalanceEntry?.BalanceNanos * 1e-9)>= 0.1))  ? (
            <>
            <div className="flex items-center">
              <input
                type="checkbox"
                checked={followBot}
                onChange={(e) => {
                  setFollowBot(e.target.checked);
                }}
              />
              <span className="ml-2">Auto Follow Assistant <i>(Beta)</i> 👥</span>
            </div>
            </>
          ) : (
            <div className="flex items-center">
              <input type="checkbox" checked disabled />
              <span className="ml-2 grid sm:grid-cols-1 md:grid-cols-2"><div>Auto Follow Assistant <i>(Beta)</i> 👥</div> <span style={{fontSize:'12px'}}>(Hold at least 0.1 DeSo Inu to activate)</span></span>
            </div>
          )}

          <div className="flex items-center">
              <span>Number of posts to fetch:</span>
              <input
                type="number"
                min="1"
                max="100"
                value={postsToFetch}
                onChange={(e) => {
                  setAutoRefetch(false);
                  clearInterval(intervalId);
                  setPostsToFetch(e.target.value);
                }}
                className="ml-2 p-2 border border-gray-300 rounded w-16"
              />
            </div>
        </div>



        <div id="latestPosts-container">
          <div id="posts-container" className="grid grid-cols-1 gap-8 my-5">
          {feed?.PostsFound.map((x,i)=>{
            return (
            <div className='bg-white p-4 mx-4 rounded-lg' key={'post'+i} >
              {x?.IsNFT? <h1 className="text-bold font-bold p-4">[NFT]</h1> :''}
              <div className="grid grid-cols-1">
                <a className="postLink bg-gray-100 md:mx-4 md:start-0 md:rounded-full md:w-8/12 md:p-4 sm:p-2" href={"https://diamondapp.com/posts/"+x?.PostHashHex} target="_blank">
                  <div className="profileEntry">
                    <div className="profilePic" style={{float:'left',backgroundImage: 'url(https://diamondapp.com/api/v0/get-single-profile-picture/'+x.ProfileEntryResponse.PublicKeyBase58Check+'?fallback=https://diamondapp.com/assets/img/default-profile-pic.png)'}}>&nbsp;</div>
                    <div className="username" style={{float: 'left'}}>{x?.ProfileEntryResponse.Username}</div>
                    <div className="coinPrice" style={{float: 'left'}}>( ~${parseFloat((((x?.ProfileEntryResponse.CoinPriceDeSoNanos)*10**-9)*desoPrice).toFixed(2))} usd )</div>
                
                  </div>
                  
                </a>
                <a className="postLink" href={"https://diamondapp.com/posts/"+x?.PostHashHex} target="_blank">
                  <div className="single-post-container m-4">
                    <div className="single-post-body truncate ">{x?.Body?.toString()}</div>
                    <div className="single-post-images-container grid grid-cols-1">
                      {x.ImageURLs?.map((url,_i)=>{
                        return (
                          <div key={"sigle-post-image"+_i}>
                          <img src={url} />
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </a>
              </div>
            </div>
            )
          })}
          </div>
        </div>
      </div>
    );
};
